import React from "react";
import {
    Container,
    Box,
    Button,
    Typography,
    TextField,
    Card,
    CardContent,
    Breadcrumbs
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import dayjs from 'dayjs';

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
import TestsController, {
    Props
} from "./TestsController";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Backdrop, CardActions } from "@mui/material";


export default class TestEdit extends TestsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const {editTestDataRecord}=this.state
        const breadcrumbs = [
             <Typography
                onClick={this.redirectTestsPage}
                data-test-id="redirectTestsBtn"
                style={{ color: "#21355F", fontSize: "24px", cursor: "pointer", fontFamily: 'Inter-Regular,sans-serif' }} key="1" >
                Tests
            </Typography>,
            <Typography
                onClick={()=>{this.handleSpecificPatient(editTestDataRecord?.attributes.patient.id)}}
                style={{ color: "#21355F", fontSize: "24px", cursor: "pointer", textDecoration: "none", fontFamily: 'Inter-Regular,sans-serif' }}
                key="2" 
                data-test-id="specificCheck" >
                {editTestDataRecord?.attributes?.patient.firstname+" "+editTestDataRecord?.attributes?.patient.lastname}
            </Typography>,
            <Typography key="3" style={{ fontSize: "24px", fontFamily: 'Inter-Bold, sans-serif', color: "#21355F"}}>Edit</Typography>,
        ];
        const institutionNumber = editTestDataRecord?.attributes?.institute?.institution_number

        return (        
            <ThemeProvider theme={theme}>
                <Container style={{ maxWidth: '100%', width: '100%', overflow: 'auto', marginBottom:'50px' ,paddingBottom: '30px'}} >
                    <Breadcrumbs
                        style={{ marginTop: '20px' }}
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>                
            
                    <Card style={{ display: 'flex', borderLeft: '4px solid #0075BF', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px', marginTop: '20px', padding: '15px 12px 15px 5px', borderRadius: '6px' }}>
                        <Box sx={{ display: 'flex', width: '25%', alignItems: 'center', flexDirection: 'column', borderRight: '3px solid rgba(33, 53, 95, 1)' }}>
                            <CardContent>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                    {"Sample ID"}
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px' }} component="div" variant="h5">
                                  {editTestDataRecord?.attributes?.test_id || "-"}
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', paddingTop: '10px', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                    {"Date of Birth"}
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px' }} component="div" variant="h5">
                                    {dayjs(editTestDataRecord?.attributes?.patient?.dob).format('DD/MM/YYYY')}
                                </Typography>
                            </CardContent>

                        </Box>
                        <Box style={{ width: '25%', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRight: '3px solid rgba(33, 53, 95, 1)' }}>
                            <CardContent>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                    Sex at Birth
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px' }} component="div" variant="h5">
                                {editTestDataRecord?.attributes?.patient?.gender}
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', paddingTop: '10px', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                    Study Date
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px' }} component="div" variant="h5">
                                    {editTestDataRecord?.attributes?.study_date ? dayjs(editTestDataRecord?.attributes?.study_date).format('DD/MM/YYYY') : "-"}
                                </Typography>
                            </CardContent>
                        </Box>
                        <Box style={{ width: '25%', borderRight: '3px solid rgba(33, 53, 95, 1)', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                            <CardContent>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                    Department
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px' }} component="div" variant="h5">
                                    {editTestDataRecord?.attributes?.department}
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', paddingTop: '10px', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                    Hospital Number
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px' }} component="div" variant="h5">
                                    {institutionNumber?.slice(0, 3) + ' ' + institutionNumber?.slice(3, 6) +  ' ' + institutionNumber?.slice(6)}
                                </Typography>
                            </CardContent>
                        </Box>
                        <Box style={{ width: '25%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                            <CardContent>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                    Clinician Name
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px' }} component="div" variant="h5">
                                {editTestDataRecord?.attributes?.clinician?.firstname +" "+editTestDataRecord?.attributes?.clinician?.lastname}
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', paddingTop: '10px', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                    Requester
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px' }} component="div" variant="h5">
                                {editTestDataRecord?.attributes?.consultant?.firstname+" "+editTestDataRecord?.attributes?.consultant?.lastname}
                                </Typography>
                            </CardContent>
                        </Box>

                    </Card>
                    <Card style={{ display: 'flex', borderLeft: '4px solid #0075BF', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px', padding: '15px 12px 15px 5px', borderRadius: '6px' }}>
                        <Box sx={{ display: 'flex', width: '25%', alignItems: 'center', flexDirection: 'column', borderRight: '3px solid rgba(33, 53, 95, 1)' }}>
                            <CardContent style={{paddingLeft:'54px'}}>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                 {editTestDataRecord?.attributes?.sample_type}                 
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '16px',marginTop: '10px' }} component="div" variant="h5">                
                                    Collective Device
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px' }} component="div" variant="h5">
                                {editTestDataRecord?.attributes?.sample_type}
                                </Typography>
                               
                            </CardContent>

                        </Box>
                        <Box style={{ width: '25%', borderRight: '3px solid rgba(33, 53, 95, 1)', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                            <CardContent>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                    Date of Test
                                </Typography>
                                <Typography style={{ fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '10px' }} component="div" variant="h5">
                                  {dayjs(editTestDataRecord?.attributes?.test_date).format('DD/MM/YYYY')}
                                </Typography>
                                <Button variant="contained"
                                    style={{ backgroundColor: '#E7205D', marginTop: '10px', color: 'white', fontFamily: 'Inter-Bold, sans-serif', borderRadius: '8px', textTransform: 'capitalize' }}
                                    data-test-id="editTestbtn"
                                    onClick={() => this.openPopUp(Number(this.state.showTests))}
                                >
                                    Edit
                                </Button>
                            </CardContent>

                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '25%', borderRight: '3px solid rgba(33, 53, 95, 1)' }}>
                            <CardContent style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                <Button onClick={()=>{this.handleRow(editTestDataRecord?.attributes?.status,editTestDataRecord?.attributes?.id)}} style={{ width: '40px', fontSize: '14px', height: '60px', borderRadius: '80%', fontFamily: 'Inter-Bold, sans-serif', border: '2px solid #21355F', color: '#21355F' }}>
                                {editTestDataRecord?.attributes?.marker_count}
                                </Button>

                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '16px', marginTop: '10px' }} component="div" variant="h5">
                                    Number of Markers
                                </Typography>

                            </CardContent>
                        </Box>
                        
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '25%' }}>

                            <CardContent style={{display: "flex",paddingRight:'50px', flexDirection: "column"}}>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                    Status
                                </Typography>

                                <Typography style={{ color: '#21355F', fontSize: '16px', marginTop: '10px' }} component="div" variant="h5">
                                {editTestDataRecord?.attributes?.status}
                                </Typography>

                            </CardContent>
                        </Box>

                    </Card>
                    <Card style={{ display: 'flex', width: '559px', alignItems: 'center', borderLeft: '4px solid #0075BF', height: '110px', flexDirection: 'column', justifyContent: 'space-between', marginBottom: '20px', padding: '15px 12px 15px 5px', borderRadius: '6px' }}>
                        <Box>
                            <CardContent style={{ width: '500px' }}>
                                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '16px' }} component="div" variant="h5">
                                    Additional Information
                                </Typography>

                                <TextField
                                    data-test-id={"testId"}
                                    name="testId"
                                    id="testId"
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            border: 'none',
                                            borderRadius: '15px',
                                            padding: '10px',
                                            marginTop: '10px',
                                            backgroundColor: "#EAE8E8",
                                        }
                                    }}
                                    onBlur={(event: any) => this.editTestDataFun(event)}
                                    onChange={this.handleInputChange}
                                    placeholder={"Please provide any additional information for the patient"}
                                    fullWidth
                                    value={this.state.setValue}
                                    type="text"
                                />
                            </CardContent>
                        </Box>
                    </Card>
                    </Container>
                <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.handlePopupData(this.state.openCalender)} >
                    {this.state.openCalender && (
                        <Card style={{
                            width: '599px', height: '480px', display: 'flex', flexDirection: 'column',
                            borderRadius: '0px 8px 32px 8px', padding: '25px 10px 15px 10px',
                            gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center'
                        }}>
                            <CardContent style={{
                                display: 'flex', flexDirection: 'column',
                                gap: '35px', alignItems: 'center', height: "400px"
                            }}>
                                <Typography style={{
                                    color: '#21355F', height: '24px', width: '512px',
                                    fontFamily: 'Inter-SemiBold, sans-serif', fontSize: '20px', textAlign: 'center'
                                }}>
                                    Select the new Date of Test
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker open
                                      onChange={(e) => this.handleDate(e)}
                                      value={dayjs(editTestDataRecord.attributes.test_date)}
                                      shouldDisableDate={this.shouldDisableDate}
                                      minDate={dayjs()}
                                        slotProps={{
                                            popper: {
                                                sx: {
                                                    ".MuiPaper-root": { marginTop: "-60px" },
                                                },
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </CardContent>
                            <CardActions style={{ display: 'flex', gap: '26px' }}>
                                <Button style={{
                                    width: '90px', height: '43px',
                                    border: '1px solid #E1E1E1',
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                                    borderRadius: '8px', background: '#FFFFFF', fontFamily: 'Inter-Bold, sans-serif', fontSize: '14px',
                                    textAlign: 'center', textTransform: 'none',
                                }}
                                    data-test-id="closebtn"
                                    onClick={() => this.closePopUp()}
                                > Back
                                </Button>
                                <Button style={{
                                    width: '90px', height: '40px',
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                                    borderRadius: '8px', background: '#0075BF', fontFamily: 'Inter-Bold, sans-serif', fontSize: '14px',
                                    textAlign: 'center', textTransform: 'none',
                                }}
                                    data-test-id="confirmBtns"
                                    onClick={() => this.confirmCal()}
                                > Confirm
                                </Button>
                            </CardActions>
                        </Card>
                    )}
                </Backdrop>
                <Backdrop style={{ zIndex: 100, color: '#FFF'}} open={this.state.popups} >
                    {
                        this.state.popups && (
                            <Card
                                style={{
                                    width: 'auto', display: 'flex', flexDirection: 'column',minHeight:'auto',maxHeight:'480px',
                                    padding: '30px 30px 24px 14px',overflow:'auto',
                                    gap: '33px', backgroundColor: '#FFFFFF', alignItems: "center"
                                }}>
                                <Typography
                                    style={{ minWidth:'340px',
                                        width: 'min-content',color: '#21355F', height: 'fit-content',
                                        fontSize: '20px', display:'flex',flexDirection:'column'
                                    }}>
                                    {
                                    this.state.popupTitle && <span style={{minWidth:'230px', paddingBottom: '15px',fontSize:'22px', fontFamily: 'Inter-Bold, sans-serif', textAlign: 'center', }}>
                                        {this.state.popupTitle}
                                    </span>
                                    }
                                    
                                    {this.state.popupTitles?.marker_text && <span style={ {textAlign:'center',paddingBottom:'15px',fontFamily: 'Inter-Bold, sans-serif', color: '#0075BF', }}>
                                        {'Markers'}
                                    </span>}
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: `repeat(${Math.min(Math.max(Math.ceil(this.state.popupTitles?.marker_text?.split(',').length / 2), 2), 3)}, 1fr)`, // Min 2, Max 3 columns
                                            gridAutoRows: 'auto',
                                            gap: '20px',
                                            fontFamily: 'Inter-regular, sans-serif',
                                            textAlign: 'left',
                                            columnGap: '30px',
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        {this.state.popupTitles?.marker_text?.split(',').map((marker: any, index: any) => (
                                            <span
                                                style={{
                                                    width: 'fit-content',
                                                    paddingLeft: '15px',
                                                    paddingRight: '10px',
                                                    paddingBottom: '3px',
                                                }}
                                                key={index}
                                            >
                                                {marker.trim()}
                                            </span>
                                        ))}
                                    </div>
                                    {this.state.popupTitles?.panel_text && <span style={{ fontSize:'22px',textAlign: 'center', paddingBottom: '15px', fontFamily: 'Inter-Bold, sans-serif', color: '#0075BF', }}>
                                        {'Panel'}
                                    </span>}
                                    <div
                                        style={{
                                            fontFamily: 'Inter-regular, sans-serif',
                                            textAlign: 'center',
                                        }}>
                                        {this.state.popupTitles?.panel_text?.split(',').map((panel: any, index: any) => (
                                             <span style={{width:'max-content',paddingLeft:'15px', paddingRight: '10px',paddingBottom:'3px' }} key={index}>{panel.trim()}</span>
                                        ))}
                                    </div>
                                </Typography>
                                <CardActions style={{ display: 'flex', gap: '26px' }}>

                                    <Button
                                        data-test-id='acceptBtnClose'
                                        onClick={() => this.handlePopup()}
                                        style={{
                                            width: '120px', height: '50px',
                                            color: '#FFFFFF',
                                            borderRadius: '8px',
                                            background: '#0075BF', textAlign: "center",
                                            fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                                            textTransform: 'uppercase', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                        }}>
                                        OK
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    }
                </Backdrop>
            </ThemeProvider>
        );
    }
}

