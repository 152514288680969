import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Typography,
  Card,
  CardContent,
  Breadcrumbs
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import GetAppIcon from '@material-ui/icons/GetApp';
import dayjs from 'dayjs';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

import TestsController, {
  Props,
} from "./TestsController";


export default class ResultTest extends TestsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const Interpretation = {
      LL: "Critical Low",
      L: "Low",
      N: "Normal",
      H: "High",
      HH:"Critical High"
    };
     /* istanbul ignore next */
    const BiomarkerTableRows = ({ biomarkerResults }:any) => (
      <>
        {biomarkerResults.map((biomarker: any, index: number) => { 
          const answer=biomarker?.interpretation as keyof typeof Interpretation
          const finalShow=isNaN(biomarker?.result) && biomarker?.result.includes('^')
          return (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {biomarker?.biomarkerName}
              </TableCell>
              <TableCell>{finalShow ? biomarker.result.split('^')[1]: biomarker.result}</TableCell>
              <TableCell>{!finalShow && biomarker?.unit}</TableCell>
              <TableCell>{!finalShow && biomarker?.interpretationRule}</TableCell>
              <TableCell>
                <Typography style={biomarker?.interpretationRule===''|| finalShow ?{}: getInterpretationStyle(Interpretation[answer])}>
                {!finalShow && Interpretation[answer]}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    ); /* istanbul ignore next */
    const getInterpretationStyle=(status:any)=> {
      if (status === 'Critical High' || status === 'Critical Low') {
        return webStyles.urgent;
      } else if (status === 'Normal') {
        return webStyles.urgents;
      } else if(status==='High' || status==='Low'){
        return webStyles.urgentss;
      }else {
        return {};
      }
    }
    const { editTestDataRecord } = this.state
    const breadcrumbs = [
      <span onClick={()=>{this.handlePatient()}} data-test-id="redirectPatientBtn" style={{ color: '#21355F', fontSize: '24px',cursor:'pointer', fontFamily: 'Inter-Regular,sans-serif', textTransform: 'capitalize' }} key="1" color="inherit" >
        Patients
      </span>,
      <span
       data-test-id="specificpatient"
        style={{ color: '#21355F',  fontSize: '24px',cursor:'pointer', fontFamily: 'Inter-Regular,sans-serif' }}
        key="2"
        color="inherit"
        onClick={()=>{this.handleSpecificPatient(editTestDataRecord?.attributes?.patient?.id)}}
      >
        {editTestDataRecord?.attributes?.patient?.firstname + " " + editTestDataRecord?.attributes?.patient?.lastname}
      </span>,
      <span
        style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '24px' }}
        key="2"
        color="inherit"
      >
        Result
      </span>,

    ];

    const institutionNumber = editTestDataRecord?.attributes?.institute?.institution_number

    return (
      <ThemeProvider theme={theme}>
        <Container style={{ overflow: 'scroll', marginBottom: '90px', maxWidth: '100%', width: '100%', fontFamily: 'Inter-Regular,sans-serif' }} >
          <Breadcrumbs
            style={{ marginTop: '20px' }}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
          <Box style={{
            display: 'flex',
            width: '100%',
            alignItems: 'baseline',
            marginBottom: '30px',
            justifyContent: 'space-between',
            fontFamily: 'Inter-Regular, sans-serif'
          }}>
            <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '24px' }} component="div">
              Basic Blood Analysis Results
            </Typography>

            <Button
              data-test-id="LoginBtn"
              style={webStyles.buttonStyle}
              onClick={()=>{this.handleNavigate(editTestDataRecord?.attributes?.result_pdf)}}
            >
              Download
              <GetAppIcon />
            </Button>
          </Box>
          <Card style={webStyles.dataCard}>
            <Box sx={webStyles.cardItem}>
              <CardContent>
                <Typography style={webStyles.resultTag} component="div" variant="h5">
                  Sample ID
                </Typography>
                <Typography style={webStyles.userData} component="div" variant="h5">
                  {editTestDataRecord?.attributes?.test_id || "-"}
                </Typography>
                <Typography style={webStyles.resultTag} component="div" variant="h5">
                  Date of Birth
                </Typography>
                <Typography style={webStyles.userData} component="div" variant="h5">
                  {dayjs(editTestDataRecord?.attributes?.patient?.dob).format('DD/MM/YYYY')}
                </Typography>
              </CardContent>

            </Box>
            <Box sx={webStyles.cardItem}>
              <CardContent>
                <Typography style={webStyles.resultTag} component="div" variant="h5">
                  Sex at Birth
                </Typography>
                <Typography style={webStyles.userData} component="div" variant="h5">
                  {editTestDataRecord?.attributes?.patient?.gender}
                </Typography>
                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '5px' }} component="div" variant="h5">
                  Study Date
                </Typography>
                <Typography style={webStyles.userData} component="div" variant="h5">
                  {dayjs(editTestDataRecord?.attributes?.study_date).format('DD/MM/YYYY')}
                </Typography>
              </CardContent>
            </Box>
            <Box sx={webStyles.cardItem}>
              <CardContent>
                <Typography style={webStyles.resultTag} component="div" variant="h5">
                  Department
                </Typography>
                <Typography style={webStyles.userData} component="div" variant="h5">
                  {editTestDataRecord?.attributes?.department}
                </Typography>
                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '5px' }} component="div" variant="h5">
                  Hospital Number
                </Typography>
                <Typography style={webStyles.userData} component="div" variant="h5">
                  {institutionNumber?.slice(0, 3) + ' ' + institutionNumber?.slice(3, 6) +  ' ' + institutionNumber?.slice(6)}
                </Typography>
              </CardContent>
            </Box>
            <Box style={{ width: '25%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CardContent>
                <Typography style={webStyles.resultTag} component="div" variant="h5">
                  Clinican Name
                </Typography>
                <Typography style={webStyles.userData} component="div" variant="h5">
                  {editTestDataRecord?.attributes?.clinician?.firstname + " " + editTestDataRecord?.attributes?.clinician?.lastname}
                </Typography>
                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '5px' }} component="div" variant="h5">
                  Requester
                </Typography>
                <Typography style={webStyles.userData} component="div" variant="h5">
                  {editTestDataRecord?.attributes?.consultant?.firstname + " " + editTestDataRecord?.attributes?.consultant?.lastname}
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Card style={webStyles.dataCard}>
            <Box sx={webStyles.cardItem}>
              <CardContent  style={{paddingLeft:'47px'}}>
                <Typography style={webStyles.resultTag} component="div" variant="h5">
                  {editTestDataRecord?.attributes?.sample_type}
                </Typography>
                <Typography style={webStyles.resultTag} component="div" variant="h5">
                 Collective Device
                </Typography>
                <Typography style={webStyles.userData} component="div" variant="h5">
                  {editTestDataRecord?.attributes?.sample_type}
                  </Typography>
                
              </CardContent>

            </Box>
            <Box sx={webStyles.cardItem}>
              <CardContent>
                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '5px' }} component="div" variant="h5">
                  Date of test
                </Typography>
                <Typography style={{...webStyles.userData, textAlign: "center"}} component="div" variant="h5">
                  {dayjs(editTestDataRecord?.attributes?.test_date).format('DD/MM/YYYY')}
                </Typography>
              </CardContent>
            </Box>
            <Box sx={webStyles.cardItem}>
              <CardContent>
                <Box style={{
                  display: "flex",
                  justifyContent: "center"
                }}>
                  <Typography style={{
                    fontFamily: 'Inter-Regular, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '5px', border: '1px solid #21355F',
                    borderRadius: '50%',
                    width: "30px",
                    height: "30px",
                    display: 'flex',
                    justifyContent: 'center',
                    placeItems: 'center'
                  }}
                    component="p">
                    {editTestDataRecord?.attributes?.marker_count}
                  </Typography>
                </Box>
                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '5px' }} component="div" variant="h5">
                  Number of Markers
                </Typography>

              </CardContent>
            </Box>
            <Box style={{ width: '25%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CardContent  style={{paddingRight:'75px'}}>
                <Typography style={{ fontFamily: 'Inter-Bold, sans-serif', color: '#21355F', fontSize: '14px', marginTop: '5px'}} component="div" variant="h5">
                  Status
                </Typography>
                <Typography style={{...webStyles.userData, textAlign: "center"}} component="div" variant="h5">
                  {editTestDataRecord?.attributes?.status}
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Box style={{ margin: '30px 0' }}>
            <TableContainer style={webStyles.tableContainerStyle}>
              <Table stickyHeader>
              <TableHead >
                  <TableRow >
                    <TableCell style={webStyles.tableRow}>BioMarker</TableCell>
                    <TableCell style={webStyles.tableRow}>Value</TableCell>
                    <TableCell style={webStyles.tableRow}>Units</TableCell>
                    <TableCell style={webStyles.tableRow}>Range</TableCell>
                    <TableCell style={webStyles.tableRow}>Interpretation</TableCell>
                  </TableRow>
                  </TableHead>
              <TableBody>
              {editTestDataRecord?.attributes?.results?.biomarkerResults && (
        <BiomarkerTableRows biomarkerResults={editTestDataRecord.attributes.results.biomarkerResults} />
      )}

                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  dataCard: {
    display: 'flex',
    borderLeft: '4px solid rgba(0, 117, 191, 1)',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    padding: '15px 12px 15px 5px',
    borderRadius: '6px'
  },
  urgents: {
    color: "#008631",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: "12px",
    width: "66px",
    padding: "2px 8px",
    backgroundColor: "#cefad0",
    fontFamily: 'Inter-Bold, sans-serif',
    lineHeight: "18px",
    fontStyle: "normal",
    textTransform: "uppercase" as const,
    borderRadius: "40px",
  },
  urgentss: {
    backgroundColor: "FEFBBD",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: "12px",
    width: "66px",
    padding: "2px 8px",
    color:'#CC7722',
    fontFamily: 'Inter-Bold, sans-serif',
    lineHeight: "18px",
    fontStyle: "normal",
    textTransform: "uppercase" as const,
    borderRadius: "40px",
  },
  urgent: {
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    width: "100px",
    padding: "2px 8px",
    display: 'flex',
    fontSize: "12px",
    fontStyle: "normal",
    justifyContent: 'center',
    fontFamily: 'Inter-Bold, sans-serif',
    alignItems: 'center',
    textTransform: "uppercase" as const,
    lineHeight: "18px",
    borderRadius: "40px",
  },
  resultTag: {
    fontFamily: 'Inter-Bold, sans-serif',
    color: '#21355F',
    fontSize: '14px',
    marginTop: '5px'
  },
  cardItem: {
    display: 'flex',
    fontFamily: 'Inter-Regular, sans-serif',
    width: '25%',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: '2px solid rgba(33, 53, 95, 1)'
  },
  userData: {
    fontFamily: 'Inter-Regular,sans-serif',
    color: '#21355F',
    fontSize: '14px',
    marginTop: '5px'
  },
  tableRow: {
    background: 'rgba(33, 53, 95, 1)',
    color: '#fff',
    fontFamily: 'Inter-Bold,sans-serif'
  },
  tableContainerStyle: {
    fontFamily: 'Inter-Regular,sans-serif',
    maxHeight: 600,
    marginTop: '10px',
    borderRadius: '8px 8px 0 0'
  },
  buttonStyle: {
    height: "44px",
    borderRadius: '8px',
    width: "180px",
    border: "none",
    textTransform: 'none' as const,
    backgroundColor: "rgba(231, 32, 93, 1)",
    cursor: "pointer",
    marginTop: "25px",
    color: "white",
    fontFamily: 'Inter-Bold, sans-serif',
    padding: "6px 10px"
  },
};
// Customizable Area End
