// Customizable Area Start
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
    Box,
    Typography,
    Card,
    CardActions,
    IconButton,
    MenuItem,
    TextField,
    InputAdornment,
    Menu,
    CircularProgress,
} from '@material-ui/core';
import {
    Search,
} from "@material-ui/icons";
import dayjs from 'dayjs';
import Select from 'react-select';
import { plusIcon, cancelIcon, editIcon, download, image_eye } from './assets';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { MoreVertOutlined } from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import TestsController, {
    Props,
} from './TestsController';

export default class Tests extends TestsController {
    isClinician: void;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const dropdownList = this.state.dropDowndata;

        const dataSource = this.getDataSource()
        return (
            <>
                <Box
                    style={{
                        width: '100%', marginBottom: '40px', paddingBottom: '30px', overflow: 'auto', display: 'flex', flexDirection: 'column',
                        padding: '20px 20px', gap: '20px'
                    }}>
                    <Typography
                        style={{
                            width: '128px', height: '40px', color: '#21355F',
                            fontFamily: 'Inter-Bold, sans-serif', fontSize: '24px'
                        }}
                    >
                        Tests
                    </Typography>

                    <Box style={{
                        display: 'flex', alignItems: 'flex-end', justifyContent: "space-between"
                    }}>
                        <TextField
                            name="search"
                            onClick={() => this.handleTestClick()}
                            autoComplete='off'
                            value={this.state.datans}
                            data-test-id="search-test-compo"
                            style={webStyle.inputField}
                            placeholder="Search"
                            onChange={(event: any) => { this.searchTest(event) }}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="search"
                                            edge="end"
                                            style={{ fontSize: "16px" }}

                                        >
                                            <Search style={{ color: "rgb(33, 53, 95)", marginRight: "5px" }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            fullWidth={true}
                        />
                        <Button
                            onClick={() => this.handleOrderTest()}
                            data-test-id='requestBtn'
                            variant='contained'
                            style={{
                                fontFamily: 'Inter-Bold, sans-serif',
                                height: "44px",
                                width: "200px",
                                border: "none",
                                backgroundColor: "rgba(231, 32, 93, 1)",
                                cursor: "pointer",
                                borderRadius: "8px",
                                color: "white",
                                textTransform: "none" as const,
                                marginLeft: "20px"

                            }}>
                            <img
                                style={{ marginRight: '10px' }}
                                src={plusIcon}
                            />
                            Order Test
                        </Button>
                    </Box>
                    <div>
                        <Card style={{ zIndex: 100, color: '#FFF' }}>
                            {this.state.handleTest && (
                                <>
                                    <Card
                                        style={{
                                            zIndex: 9999,
                                            width: 'auto',
                                            maxWidth: '75%',
                                            position: 'absolute',
                                            maxHeight: '300px',
                                            overflow: 'auto',
                                            borderRadius: '8px', padding: '12px 10px 10px 10px',
                                            gap: '10px', backgroundColor: '#FFFFFF', alignItems: 'center',
                                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                                        }}>
                                        <CloseIcon data-test-id="checkicon" style={{ cursor: 'pointer', height: '16px', position: 'relative', top: '-5px', right: '3px' }} onClick={() => this.handleTestClick2()} />
                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
                                            <Select
                                                isSearchable={false}
                                                id="selectedOptions"
                                                data-test-id="selectdata"
                                                value={null}
                                                closeMenuOnSelect={true}
                                                key="Patient Name"
                                                onChange={(event:any) => this.handleChange(event)}
                                                placeholder="Patient Name"
                                                options={this.getUniqueOptions(dropdownList, (option: { attributes: { patient: { firstname: any; lastname: any; }; }; }) => `${option.attributes.patient.firstname} ${option.attributes.patient.lastname}`)}
                                                hideSelectedOptions={false}
                                                styles={this.customTestStyles}
                                                menuPortalTarget={document.body}
                                            />

                                            <Select
                                                data-test-id="selectdata3"
                                                value={null}
                                                isSearchable={false}
                                                id="selectedOptions3"
                                                onChange={(event:any) => this.handleChange3(event)}
                                                key="Date Requested"
                                                placeholder="Date Requested"
                                                closeMenuOnSelect={true}
                                                options={this.getUniqueOptions(dropdownList, (option: { attributes: { requested_date: any; }; }) => option.attributes.requested_date)}
                                                hideSelectedOptions={false}
                                                styles={this.customTestStyles}
                                                menuPortalTarget={document.body}
                                            />
                                            <Select
                                                onChange={(event:any) => this.handleChange4(event)}
                                                data-test-id="selectdata4"
                                                value={null}
                                                isSearchable={false}
                                                key="Date Received"
                                                id="selectedOptions4"
                                                placeholder="Date Received"
                                                closeMenuOnSelect={true}
                                                options={this.getUniqueOptions(dropdownList, (option: { attributes: { date_received: any; }; }) => option.attributes.date_received)}
                                                hideSelectedOptions={false}
                                                styles={this.customTestStyles}
                                                menuPortalTarget={document.body}
                                            />
                                            <Select
                                                isSearchable={false}
                                                id="selectedOptions5"
                                                data-test-id="selectdata5"
                                                value={null}
                                                onChange={this.handleChange5}
                                                key="Sample Type"
                                                placeholder="Sample Type"
                                                isMulti={true}
                                                closeMenuOnSelect={true}
                                                options={this.getUniqueOptions(dropdownList, (option: { attributes: { sample_type: any; }; }) => option.attributes.sample_type)}
                                                hideSelectedOptions={false}
                                                styles={this.customTestStyles}
                                                menuPortalTarget={document.body}
                                            />
                                            <Select
                                                onChange={this.handleChange6}
                                                data-test-id="selectdata6"
                                                value={null}
                                                isMulti={true}
                                                isSearchable={false}
                                                id="selectedOptions6"
                                                key="Clinician Requested"
                                                placeholder="Clinician Requested"
                                                closeMenuOnSelect={true}
                                                options={this.getUniqueOptions(dropdownList, (option: { attributes: { clinician: { firstname: any; lastname: any; }; }; }) => `${option.attributes.clinician.firstname} ${option.attributes.clinician.lastname}`)}
                                                hideSelectedOptions={false}
                                                styles={this.customTestStyles}
                                                menuPortalTarget={document.body}
                                            />
                                            <Select
                                                isSearchable={false}
                                                id="selectedOptions7"
                                                data-test-id="selectdata7"
                                                value={null}
                                                isMulti={true}
                                                key="Department"
                                                onChange={ this.handleChange7}
                                                placeholder="Department"
                                                closeMenuOnSelect={true}
                                                options={this.getUniqueOptions(dropdownList, (option: { attributes: { department: any; }; }) => option.attributes.department)}
                                                hideSelectedOptions={false}
                                                styles={this.customTestStyles}
                                                menuPortalTarget={document.body}
                                            />
                                            <Select
                                                onChange={this.handleChange8}
                                                data-test-id="selectdata8"
                                                value={null}
                                                isMulti={true}
                                                key="Consultant"
                                                isSearchable={false}
                                                id="selectedOptions8"
                                                placeholder="Consultant"
                                                closeMenuOnSelect={true}
                                                options={this.getUniqueOptions(dropdownList, (option: { attributes: { consultant: { firstname: any; lastname: any; }; }; }) => `${option.attributes.consultant.firstname} ${option.attributes.consultant.lastname}`)}
                                                hideSelectedOptions={false}
                                                styles={this.customTestStyles}
                                                menuPortalTarget={document.body}
                                            />
                                            <Select
                                                data-test-id="selectdata9"
                                                value={null}
                                                onChange={(event:any) => this.handleChange9(event)}
                                                key="Priority"
                                                placeholder="Priority"
                                                closeMenuOnSelect={true}
                                                options={this.getUniqueOptions(dropdownList, (option: { attributes: { priority: any; }; }) => option.attributes.priority)}
                                                isSearchable={false}
                                                id="selectedOptions9"
                                                hideSelectedOptions={false}
                                                styles={this.customTestStyles}
                                                menuPortalTarget={document.body}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', paddingTop: '15px' }}>
                                            {this.renderSelectedOption()}
                                            {this.renderSelectedOption3()}
                                            {this.renderSelectedOption4()}
                                            {this.renderFinalSelectedOptions()}
                                            {this.renderSelectedOption9()}
                                            {(this.state.selectedOptions?.label || this.state.selectedOptions3?.label || this.state.selectedOptions4?.label || this.state.selectedOptions5?.length>0
                                                || this.state.selectedOptions6?.length>0 || this.state.selectedOptions7?.length>0 || this.state.selectedOptions8?.length>0 || this.state.selectedOptions9?.label) &&
                                                <div
                                                    onClick={() => { this.removeAllOptions() }}
                                                    style={{
                                                        margin: '5px',
                                                        borderRadius: '20px',
                                                        padding: '10px',
                                                        width: 'fit-content',
                                                        color: '#64748B',
                                                        textAlign: 'center',
                                                        overflow: 'hidden',
                                                        cursor: 'pointer',
                                                        fontFamily: 'Inter-Bold, sans-serif',
                                                    }}>
                                                    Clear all
                                                </div>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', fontSize: '16px' }}>
                                            {this.state.getCompleteSuggestions?.map((item: any, index: any) => (
                                                <this.SuggestionItem
                                                    key={index}
                                                    data-test-id="testest"
                                                    item={item}
                                                    searchText={this.state.datans}
                                                    onClick={() => this.searchTest(item)}
                                                />
                                            ))}
                                        </div>
                                    </Card>
                                </>
                            )}
                        </Card>

                    </div>
                    {this.state.dataFetching ? 
                        <Box style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
                            <CircularProgress />
                        </Box>    :  
                        <TableContainer style={{ borderRadius: '8px 8px 0px 0px', marginBottom: '55px' }}>
                            <Table aria-label='simple table'>
                                <TableHead style={webStyle.tableHead}>
                                    <TableRow>
                                        <TableCell style={{ ...webStyle.tableCellRow,textAlign:'center' }}>Patient Name</TableCell>
                                        <TableCell style={{ ...webStyle.tableCellRow,textAlign:'center'   }} align='left'>NHS Number</TableCell>
                                        <TableCell style={{ ...webStyle.tableCellRow, textAlign:'center'  }} align='left' >Date Requested</TableCell>
                                        <TableCell style={{ ...webStyle.tableCellRow,textAlign:'center'  }} align='left'>Date Received</TableCell>
                                        <TableCell style={{ ...webStyle.tableCellRow,textAlign:'center'  }} align='left'>Sample Type</TableCell>
                                        <TableCell style={{ ...webStyle.tableCellRow, textAlign:'center'  }} align='left'>Clinician Requested</TableCell>
                                        <TableCell style={{ ...webStyle.tableCellRow, textAlign:'center' }} align='left'>Department</TableCell>
                                        <TableCell style={{ ...webStyle.tableCellRow, textAlign:'center' }} align='left'>Consultant</TableCell>
                                        <TableCell style={{ ...webStyle.tableCellRow,textAlign:'center' }} align='left'>Priority</TableCell>
                                        <TableCell style={{ ...webStyle.tableCellRow,textAlign:'center' }} align='left'></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataSource?.map((item: any, key) => {
                                        const isPriority =this.checkPriority(item.attributes?.priority)
                                        const answer=this.handleAnswer(item.attributes?.priority)
                                        const applyStyle=isPriority ?{...webStyle.tableCellBody,width:'auto',display:'flex',height:'48px',alignItems:'center',justifyContent:'center',paddingLeft:'15px'}:{ ...webStyle.tableCellBody,width:'auto',textAlign:'center'}
                                        const cellStyle = isPriority ? { ...webStyle.urgent, width: 'auto' } : { ...webStyle.tableCellBody, width: 'auto', borderBottom: 'none' };

                                        return (
                                            <TableRow key={key}>
                                                <TableCell data-test-id="row1" onClick={() => { this.handleRow(item.attributes.status, item.id) }}
                                                    style={{ ...webStyle.tableCellBody,  textAlign:'center'  ,textDecoration: 'underline'}}
                                                    align='left'
                                                >
                                                    {item.attributes.patient.firstname + " " + item.attributes.patient.lastname}
                                                </TableCell>
                                                <TableCell data-test-id="row2" onClick={() => { this.handleRow(item.attributes.status, item.id) }} style={{ ...webStyle.tableCellBody, textAlign:'center'}} align='left'>
                                                    {item.attributes.patient.nhs_number.slice(0, 3) + ' ' +
                                                        item.attributes.patient.nhs_number.slice(3, 6) + ' ' +
                                                        item.attributes.patient.nhs_number.slice(6)}
                                                </TableCell>
                                                <TableCell data-test-id="row3" onClick={() => { this.handleRow(item.attributes.status, item.id) }} style={{ ...webStyle.tableCellBody,  textAlign:'center'}} align='left'>
                                                    {this.formatDate(item.attributes.requested_date)}
                                                </TableCell>
                                                <TableCell data-test-id="row4" onClick={() => { this.handleRow(item.attributes.status, item.id) }} style={{ ...webStyle.tableCellBody, textAlign:'center'}} align='left'>
                                                    {this.formatDate(item.attributes.date_received)}
                                                </TableCell>
                                                <TableCell data-test-id="row5" onClick={() => { this.handleRow(item.attributes.status, item.id) }} style={{ ...webStyle.tableCellBody, textAlign:'center'}} align='left'>{item.attributes.sample_type}</TableCell>
                                                <TableCell data-test-id="row6" onClick={() => { this.handleRow(item.attributes.status, item.id) }} style={{ ...webStyle.tableCellBody,textAlign:'center'}} align='left'>
                                                    {item?.attributes?.clinician?.firstname + " " + item?.attributes?.clinician?.lastname}
                                                </TableCell>
                                                <TableCell data-test-id="row7" onClick={() => { this.handleRow(item.attributes.status, item.id) }} style={{ ...webStyle.tableCellBody, textAlign:'center'}} align='left'>{item.attributes.department}</TableCell>
                                                <TableCell data-test-id="row8" onClick={() => { this.handleRow(item.attributes.status, item.id) }} style={{ ...webStyle.tableCellBody, textAlign:'center'}} align='left'>
                                                    {item?.attributes?.consultant?.firstname + " " + item?.attributes?.consultant?.lastname}
                                                </TableCell>
                                                <TableCell data-test-id="row9" onClick={() => { this.handleRow(item.attributes.status, item.id) }} style={{...applyStyle,textAlign:'center'} }>
                                                    <Typography
                                                        style={cellStyle}
                                                        data-test-id="naviToResult"
                                                    >
                                                       {answer}
                                                    </Typography>
                                                </TableCell >
                                               
                                                <TableCell style={{ ...webStyle.tableCellBody, width: "3%" }}>
                                                    <IconButton
                                                        data-test-id='iconButton'
                                                        aria-label='more'
                                                        id='long-button'
                                                        aria-haspopup='true'
                                                        disabled={item.attributes.status.toLowerCase() === "cancelled"}
                                                        onClick={(event:any) => this.handleSuccess(event, item.attributes.status, item.id, item.attributes.firts_name + " " + item.attributes.lastname)}
                                                    >
                                                        <MoreVertOutlined />
                                                    </IconButton>
                                                    <Menu
                                                        id="long-menu"
                                                        onClose={() => this.closeSuccess()}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'long-button',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        getContentAnchorEl={null}
                                                        style={{ top: '-30px', left: '-45px', borderRadius: '8px' }}
                                                        anchorEl={anchorEl}
                                                        data-test-id='closeRequest'
                                                        open={open}
                                                        PaperProps={{
                                                            style: {
                                                                maxHeight: 100,
                                                                width: '24ch',
                                                                boxShadow: 'none',
                                                                border: "1px solid #B4B4B4",
                                                                borderRadius: "8px"
                                                            },
                                                        }}
                                                    >
                                                        {this.state.statusTest === 'Requested' ? (
                                                            <Box>
                                                                <MenuItem data-test-id="editTestBtn" onClick={() => this.handleEditTest(this.state.testId)} style={webStyle.menuItem} key={"Edit Test"}>
                                                                    <Box style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'end',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <img
                                                                            style={{ marginRight: '10px', height: '20px' }}
                                                                            src={editIcon}
                                                                        />
                                                                        <span style={{ fontSize: '16px', lineHeight: '20px' }}>
                                                                            Edit Test
                                                                        </span>
                                                                    </Box>
                                                                </MenuItem>
                                                                <MenuItem data-test-id="closeRequestBtn" onClick={() => this.handleReorder()} style={webStyle.menuItem} key={"Cancel Test"}>
                                                                    <Box style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'end',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <img
                                                                            style={{ marginRight: '10px', height: '20px' }}
                                                                            src={cancelIcon}
                                                                        />
                                                                        <span style={{ fontSize: '16px', lineHeight: '20px' }}>
                                                                            Cancel Test
                                                                        </span>
                                                                    </Box>
                                                                </MenuItem>
                                                            </Box>
                                                        ) : (
                                                            <Box>
                                                                <MenuItem data-test-id="checkItem22" onClick={() => this.handleResultTest(this.state.testId)} style={webStyle.menuItem} key={"View Results"}>
                                                                    <Box style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'end',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <img
                                                                            style={{ marginRight: '10px', height: '20px' }}
                                                                            src={image_eye}
                                                                        />
                                                                        <span style={{ fontSize: '16px', lineHeight: '20px' }}>
                                                                            View Results
                                                                        </span>
                                                                    </Box>
                                                                </MenuItem>
                                                                <MenuItem data-test-id="checkItem" onClick={() => { this.handleNavigate(this.state.editTestDataRecord?.attributes?.result_pdf) }} style={webStyle.menuItem} key={"Download Results"}>
                                                                    <Box style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'end',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <img
                                                                            style={{ marginRight: '10px', height: '20px' }}
                                                                            src={download}
                                                                        />
                                                                        <span style={{ fontSize: '16px', lineHeight: '20px' }}>
                                                                            Download Results
                                                                        </span>
                                                                    </Box>
                                                                </MenuItem>

                                                            </Box>
                                                        )}
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </Box>
                <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.state.openReorderPopup} >
                    {
                        this.state.openReorderPopup && (
                            <Card
                                style={{
                                    width: '599px', display: 'flex', flexDirection: 'column',
                                    borderRadius: '8px', padding: '22px 0px 24px 0px',
                                    gap: '33px', backgroundColor: '#FFFFFF', alignItems: "center"
                                }}>
                                <Typography
                                    style={{
                                        color: '#21355F', height: 'fit-content', width: '465px',
                                        fontSize: '20px', textAlign: 'center'
                                    }}>
                                    <span style={{ fontFamily: 'Inter-Bold, sans-serif' }}>Are you sure you want to cancel this test?</span>
                                </Typography>
                                <CardActions style={{ display: 'flex', gap: '26px' }}>
                                    <Button
                                        style={{
                                            width: '120px', height: '56px', border: '1px solid #E1E1E1',
                                            color: '#0075BF',
                                            borderRadius: '8px', background: '#FFFFFF', textAlign: "center",
                                            fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                                            textTransform: 'none', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                        }}
                                        data-test-id='closeReorderBtn'
                                        onClick={() => this.closeReorder()}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        data-test-id='acceptBtn'
                                        onClick={() => this.closeTest(this.state.testId)}
                                        style={{
                                            width: '120px', height: '56px',
                                            color: '#FFFFFF',
                                            borderRadius: '8px', background: '#0075BF', textAlign: "center",
                                            fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                                            textTransform: 'none', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                        }}>

                                        Accept
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    }
                </Backdrop>
                <Backdrop style={{ zIndex: 100, color: '#FFF' }} open={this.state.popup} >
                    {
                        this.state.popup && (
                            <Card
                                style={{
                                    width: 'auto', display: 'flex', flexDirection: 'column',
                                    borderRadius: '0px 8px 33px 8px',
                                    padding: '30px 30px 24px 30px',
                                    gap: '33px', backgroundColor: '#FFFFFF', alignItems: "center"
                                }}>
                                <Typography
                                    style={{
                                        color: '#21355F', height: 'fit-content', width: '465px',
                                        fontSize: '20px', textAlign: 'center'
                                    }}>
                                    <span style={{ fontFamily: 'Inter-Bold, sans-serif' }}>
                                        {this.state.popupTitle}
                                    </span>
                                </Typography>
                                <CardActions style={{ display: 'flex', gap: '26px' }}>

                                    <Button
                                        data-test-id='acceptBtnClose'
                                        onClick={() => this.handlePopup()}
                                        style={{
                                            width: '120px', height: '50px',
                                            color: '#FFFFFF',
                                            borderRadius: '8px',
                                            background: '#0075BF', textAlign: "center",
                                            fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                                            textTransform: 'uppercase', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                        }}>
                                        OK
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    }
                </Backdrop>
                <Backdrop style={{ zIndex: 100, color: '#FFF'}} open={this.state.popups} >
                    {
                        this.state.popups && (
                            <Card
                                style={{
                                    width: 'auto', display: 'flex', flexDirection: 'column',minHeight:'auto',maxHeight:'480px',
                                    padding: '30px 30px 24px 14px',overflow:'auto',
                                    gap: '33px', backgroundColor: '#FFFFFF', alignItems: "center"
                                }}>
                                <Typography
                                    style={{ minWidth:'340px',
                                        width: 'min-content',color: '#21355F', height: 'fit-content',
                                        fontSize: '20px', display:'flex',flexDirection:'column'
                                    }}>
                                    <span style={{minWidth:'230px', paddingBottom: '15px',fontSize:'22px', fontFamily: 'Inter-Bold, sans-serif', textAlign: 'center', }}>
                                        {this.state.popupTitle}
                                    </span>
                                    {this.state.popupTitles?.marker_text && <span style={ {textAlign:'center',paddingBottom:'15px',fontFamily: 'Inter-Bold, sans-serif', color: '#0075BF', }}>
                                        {'Markers'}
                                    </span>}
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: `repeat(${Math.min(Math.max(Math.ceil(this.state.popupTitles?.marker_text?.split(',').length / 2), 2), 3)}, 1fr)`, // Min 2, Max 3 columns
                                            gridAutoRows: 'auto',
                                            gap: '20px',
                                            fontFamily: 'Inter-regular, sans-serif',
                                            textAlign: 'left',
                                            columnGap: '30px',
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        {this.state.popupTitles?.marker_text?.split(',').map((marker: any, index: any) => (
                                            <span
                                                style={{
                                                    width: 'fit-content',
                                                    paddingLeft: '15px',
                                                    paddingRight: '10px',
                                                    paddingBottom: '3px',
                                                }}
                                                key={index}
                                            >
                                                {marker.trim()}
                                            </span>
                                        ))}
                                    </div>
                                    {this.state.popupTitles?.panel_text && <span style={{ fontSize:'22px',textAlign: 'center', paddingBottom: '15px', fontFamily: 'Inter-Bold, sans-serif', color: '#0075BF', }}>
                                        {'Panel'}
                                    </span>}
                                    <div
                                        style={{
                                            fontFamily: 'Inter-regular, sans-serif',
                                            textAlign: 'center',
                                        }}>
                                        {this.state.popupTitles?.panel_text?.split(',').map((panel: any, index: any) => (
                                             <span style={{width:'max-content',paddingLeft:'15px', paddingRight: '10px',paddingBottom:'3px' }} key={index}>{panel.trim()}</span>
                                        ))}
                                    </div>
                                </Typography>
                                <CardActions style={{ display: 'flex', gap: '26px' }}>

                                    <Button
                                        data-test-id='acceptBtnClose'
                                        onClick={() => this.handlePopup()}
                                        style={{
                                            width: '120px', height: '50px',
                                            color: '#FFFFFF',
                                            borderRadius: '8px',
                                            background: '#0075BF', textAlign: "center",
                                            fontFamily: 'Inter-Bold, sans-serif', fontSize: '16px',
                                            textTransform: 'uppercase', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                        }}>
                                        OK
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    }
                </Backdrop>
            </>
        );
    }
}
const webStyle = {
    inputField: {
        color: "rgb(33, 53, 95) !important",
        border: "1px solid rgba(33, 53, 95, 1)",
        padding: "5px 0px 5px 0px",
        borderRadius: "98px",
        background: "none",
        "&:before": {
            border: "none !important"
        },
        "&:after": {
            border: "none"
        },
        "&:hover": {
            border: "2px solid #0075BF"
        }
    },
    tableHead: {
        backgroundColor: '#21355F',
        borderRadius: '8px 8px 0px 0px',
    },

    tableCellRow: {
        color: '#FFFFFF',
        width: 'fit-content',
        height: '22px',
        fontFamily: 'Inter-Bold, sans-serif',
        fontSize: '14px',
        padding: '8px 0 8px 10px'

    },
    tableCellBody: {
        color: '#21355F',
        width: 'fit-content',
        height: '22px',
        cursor:'pointer',
        fontFamily: 'Inter-Regular, sans-serif',
        fontSize: '14px',
        padding: '12px 0 12px 10px',
        borderBottom: '1px solid #0075BF',
    },
    menuItem: {
        height: '40px',
        color: "#21355F",
        fontSize: '14px',
        fontFamily: 'Inter-Regular, sans-serif',
    },
    urgent: {
        width: "66px",
        padding: "2px 8px",
        backgroundColor: "#FEE2E2",
        color: "#DC2626",
        fontSize: "12px",
        fontStyle: "normal",
        fontFamily: 'Inter-Bold, sans-serif',
        lineHeight: "18px",
        textTransform: "uppercase" as const,
        borderRadius: "40px",
        cursor: "pointer"
    },
};
// Customizable Area End
